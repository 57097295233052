export const pl = {
  auth: {
    sign_in: "Zaloguj się",
    signing_in: "Logowanie",
    signing_up: "Rejestracja",
    sign_up: "Zarejestruj się",
    sign_up_promo: "Zarejestruj się w Legal Torch i odkryj nowe możliwości!",
    login: "Zaloguj się",
    logout: "Wyloguj się",
    register: "Zarejestruj się",
    password: "Hasło",
    forgot_password: "Odzyskiwanie hasła",
    forgot_password_link: "Zapomniałeś hasła?",
    confirm_password: "Powtórz hasło",
    create_account: "Nie masz konta?",
    already_have_account: "Masz już konto?",
    reset_password: "Zmień hasło",
    "Reset Password": "Zmień hasło",
    recaptcha: "Potwierdź, że nie jesteś robotem",
    remember_me: "Zapamiętaj mnie",
    remember_me_hint: "Pozostań zalogowany przez 7 dni",
    remember_the_password: "Pamiętasz hasło?",
    need_help: "Potrzebujesz pomocy?",
  },
  invoice: {
    address: "Adres",
    city: "Miasto",
    zip_code: "Kod pocztowy",
    country: "Kraj",
    tin_number: "NIP",
  },
  chat: {
    ai_assistant: "Asystent Prawny AI",
    new_document_welcome_message: "Nie wiesz, który dokument wybrać? Zapytaj mnie!",
    do_you_need_help: "Potrzebujesz pomocy?",
    ask_legal_assistant: "Zapytaj Asystenta Prawnego AI",
    virtual_assistant: "Asystent Prawny AI",
    ai_legal_assistant: "Asystent Prawny AI",
    lawyer: "Prawnik",
    message_placeholder: "Masz pytanie? Jestem tu, aby pomóc!",
    creator_welcome_message:
      "Witaj! Jestem Asystentem Prawnym AI. Jestem tu, aby pomóc - zapytaj mnie o cokolwiek.",
    disclaimer:
      "Ta wiadomość została wygenerowana przez AI i nie powinna być traktowana jako porada prawna.",
    disclaimer_1: "Aby skorzystać z Asystenta Prawnego AI, przeczytaj poniższe informacje:",
    disclaimer_2:
      "Pamiętaj: Legal Torch jest narzędziem do generowania umów i nie zastępuje profesjonalnej porady prawnej. W razie wątpliwości skonsultuj się z prawnikiem.",
    disclaimer_3: "Zapisz mój wybór, by nie potwierdzać tego ponownie",
    disclaimer_4: "Tak, rozumiem",
    sample_messages: ["Podsumuj dokument", "Podaj kluczowe zagadnienia"],
  },
  billing: {
    plan: "Plan",
    next_payment: "Następna płatność",
    subscription_cancelled: "Anulowano pomyślnie",
    subscription_resumed: "Wznowiono pomyślnie",
    subscription: "Szczegóły subskrypcji",
    starting_from: "Obowiązuje od",
    available_plans: "Dostępne plany",
    subscription_coverage: "Zakres subskrypcji",
    extended_subscription: "Subskrypcja premium",
    choose_subscription: "Wybierz plan subskrypcji",
    cancel_subscription: "Anuluj subskrypcję",
    cancel_subscription_message: "Potwierdzasz rezygnację z subskrypcji? Zastanów się jeszcze!",
    payment_history: "Historia płatności",
    payment_method: "Metoda płatności",
    payment_date: "Data płatności",
    payment_required: "Wymagana płatność",
    payment_subtitle: "Aby sfinalizować dokument, prosimy o dokonanie płatności.",
    per_month: "netto miesięcznie",
    per_document: "netto za dokument",
    buy_now: "Kup teraz",
    total: "Suma",
    plans_benefits1: [
      [
        "Wypróbuj naszą platformę przez 12 miesięcy",
        "Nieograniczone tworzenie dokumentów",
        "Pełny dostęp do wszystkich funkcji",
      ],
      [
        "W pełni wykorzystaj możliwości naszej platformy",
        "Równowaga między elastycznością a długoterminową korzyścią",
        "Bestseller wśród naszych planów",
      ],
      [
        "Bestseller wśród naszych planów",
        "W pełni zintegruj narzędzia ze swoimi procesami",
        "Najlepszy wybór dla długoterminowej efektywności i oszczędności",
      ],
    ],
    plans_benefits2: {
      one_time: [
        "Jednorazowe tworzenie dokumentu",
        "Dostosuj i pobierz natychmiast",
        "Idealne dla okazjonalnych użytkowników",
      ],
      recurring: ["Nieograniczone tworzenie dokumentów", "Pełny dostęp do wszystkich funkcji"],
    },
    extended_option: "Opcja jednorazowa - wybierz, zapłać i korzystaj od razu",
    selected_templates: "Wybrane szablony",
    selected_templates_details:
      "(edycja, personalizacja, drukowanie, pobieranie, udostępnianie, tryb negocjacji, podpis elektroniczny)",
    ai_assistant: "Wsparcie AI dla wybranych dokumentów",
    ai_assistant_details:
      "Ograniczony dostęp do Asystenta Prawnego AI: Wyszukiwanie umów - 20 000 znaków/miesiąc i pomoc w personalizacji dokumentu - 100 000 znaków/miesiąc",
    limited_dashboard: "Ograniczony dostęp do panelu użytkownika",
    limited_dashboard_details: "(brak możliwości tworzenia folderów)",
    single_document: "Pojedynczy dokument",
    free_plan: "Plan Darmowy",
    free_subscription:
      "Korzystasz z Planu Darmowego! Ciesz się wyszukiwaniem dokumentów i korzystaniem z darmowych szablonów z podstawową edycją. Chcesz więcej funkcji? W każdej chwili możesz ulepszyć swój plan lub kupić pojedyncze dokumenty!",
    free_subscription_details: "Darmowe szablony",
    free_subscription_details_info:
      "(edycja, personalizacja, drukowanie, pobieranie, udostępnianie, tryb negocjacji, podpis elektroniczny)",
    free_subscription_ai_assistant: "Asystent Prawny AI",
    free_subscription_ai_assistant_info:
      "(ograniczony dostęp do Asystenta Prawnego AI: Wyszukiwanie umów - 10 000 znaków/miesiąc i pomoc w personalizacji dokumentu - 20 000 znaków/miesiąc)",
    free_subscription_dashboard: "Ograniczony dostęp do panelu użytkownika",
    free_subscription_dashboard_details: "(bez możliwości tworzenia folderów)",
    join_premium_subscription:
      "Dołącz do naszego Planu Premium, aby uzyskać nieograniczony dostęp do wszystkich szablonów, pełne wsparcie Asystenta Prawnego AI i kompletny panel do łatwego zarządzania umowami. Dziękujemy za wybór subskrypcji!",
    premium_plan: "Plan Premium",
    premium_subscription:
      "Witaj w Planie Premium🎉! Ciesz się nieograniczonym dostępem do pełnej biblioteki dokumentów, spersonalizowanym wsparciem Asystenta Prawnego AI i kompleksowym panelem do łatwego zarządzania umowami. Dziękujemy, że jesteś cenionym członkiem naszej społeczności!",
    premium_subscription_details:
      "Ciesz się nieograniczonym dostępem do wszystkich szablonów przez cały okres subskrypcji!",
    premium_subscription_details_info:
      "(Edycja, personalizacja, drukowanie, pobieranie, udostępnianie, tryb negocjacji, duplikowanie umów)",
    premium_subscription_ai_assistant: "Nieograniczony dostęp do Asystenta Prawnego AI",
    premium_subscription_dashboard: "Pełny dostęp do panelu użytkownika",
    traditional_payments: "Płatności kartą",
    crypto_payments: "Zapłać VAI Tokenem",
  },
  documents: {
    new_document: "Nowy dokument",
    new_document_instruction: "Instrukcja",
    new_document_instruction_step_1: "1. Wybierz szablon odpowiadający Twoim potrzebom.",
    new_document_instruction_step_2:
      "2. Nie wiesz, który szablon wybrać? Zapytaj Asystenta Prawnego AI lub skorzystaj z wyszukiwarki.",
    new_document_instruction_step_3: "3. Wypełnij dokument swoimi danymi.",
    new_document_instruction_step_4:
      "4. Jeśli to konieczne, możesz podpisać dokument elektronicznie.",
    popular_templates: "Popularne szablony",
    my_documents: "Moje dokumenty",
    use_template: "Wybierz szablon",
    create_document: "Nowy dokument",
    create_directory: "Nowy folder",
    remove_directory: "Usuń folder",
    no_documents: "Brak dostępnych dokumentów.",
    new_directory_subtitle: "Wprowadź nazwę dla folderu",
    move_to_directory: "Przenieś do folderu",
    remove_from_directory: "Usuń z folderu",
    move_to_new_directory: "Przenieś do nowego folderu...",
    show_history: "Pokaż historię",
    change_history: "Historia zmian",
    current_version: "Aktualna wersja",
    previous_version: "Poprzednia wersja",
    next_version: "Kolejna wersja",
    send_to_verification: "Wyślij do weryfikacji",
    preview: "Podgląd",
    name: "Nazwa dokumentu",
    folder_name: "Nazwa folderu",
    type: "Typ",
    status: "Status",
    statuses: {
      Draft: "Wersja robocza",
      "Ready for review or signature": "Do weryfikacji lub podpisu",
      Negotiation: "W trakcie negocjacji",
      "Ready for signature": "Gotowy do podpisu",
      Signed: "Podpisany",
    },
    signatures: {
      sign: "Podpisz",
      sign_document: "Podpisz dokument",
      sign_documents: "Podpisz dokumenty",
      documents_to_sign: "Dokumenty do podpisania",
      upload_new_document_and_sign: "Wgraj i podpisz nowy dokument",
      pdf_files_digitally: "Podpisz elektronicznie pliki PDF",
      select_a_document_from_the_platform: "Wybierz dokument z platformy",
      select_a_document_from_your_device: "Wybierz plik ze swojego urządzenia",
      or_drag_and_drop_here: "Lub przeciągnij i upuść tutaj",
      document_size: "Rozmiar dokumentu",
      document_extension: "Format pliku",
      signing_format: "Format podpisu",
      pdf_file_in_pades: "Plik PDF w formacie PAdES",
      go_sign: "Przejdź do podpisu",
      other_in_xades: "Inne w formacie XAdES",
      learn_more_about_pades_and_xades_formats: "Dowiedz się więcej o formatach PAdES i XAdES",
      recommended_browsers: "Zalecane przeglądarki",
      show_ready_documents: "Zobacz dokumenty oczekujące na Twój podpis",
    },
    duplicate: "Duplikuj",
    accept_document: "Zaakceptuj dokument",
    document_accepted: "Dokument zaakceptowany",
    negotiation_header: "Witaj w trybie negocjacji – zaczynajmy!",
    negotiation_description:
      "W tym module możesz dodawać komentarze do dokumentu lub konkretnych sekcji, aby zasugerować zmiany. Jeśli dokument spełnia Twoje oczekiwania, kliknij przycisk poniżej, aby go zaakceptować. Druga strona zostanie automatycznie powiadomiona o Twojej decyzji. Rozpocznij negocjacje i współpracuj, aby osiągnąć najlepszy rezultat!",
    subscription_or_single_payment_required:
      "Aby edytować ten dokument, wykup go ponownie lub przejdź na plan subskrypcyjny",
    delete_document: 'Czy na pewno chcesz usunąć dokument "{name}"',
    delete_document_description: "Potwierdź, że chcesz usunąć ten dokument.",
    delete_success: "Dokument został pomyślnie usunięty",
    show_comments: "Pokaż komentarze",
    sign_hint: "Uzupełnij pozostałe pola w formularzu umowy",
  },
  common: {
    email: "Email",
    name: "Imię i nazwisko",
    phone: "Numer telefonu",
    or: "lub",
    and: "i",
    edit: "Edytuj",
    file: "Plik",
    share: "Udostępnij",
    search_bar_placeholder: "Wyszukaj lub zapytaj Asystenta Prawnego AI",
    home: "Strona główna",
    new_document: "Nowy dokument",
    documents: "Dokumenty",
    profile: "Profil",
    guest: "Gość",
    search: "Wyszukaj",
    created_at: "Utworzono",
    show: "Pokaż",
    preview: "Podgląd",
    hide: "Ukryj",
    send: "Wyślij",
    save: "Zapisz",
    load_more: "Pokaż więcej",
    cancel: "Anuluj",
    create: "Utwórz",
    upgrade_subscription: "Ulepsz plan",
    upgrade: "Ulepsz",
    confirm: "Potwierdź",
    confirm_changes: "Czy chcesz zapisać zmiany?",
    discard: "Odrzuć",
    resume: "Wznów",
    manage: "Zarządzaj",
    close: "Zamknij",
    choose: "Wybierz",
    status: "Status",
    starts_at: "Subskrypcja od",
    ends_at: "Koniec subskrypcji",
    previous: "Poprzedni",
    next: "Następny",
    save_as: "Zapisz jako",
    print: "Drukuj",
    delete: "Usuń",
    move: "Przenieś",
    directory: "Katalog",
    reset: "Resetuj",
    requiresAuth: "Wymagana autoryzacja",
    requiresAuthMessage: "Musisz być zalogowany, aby uzyskać dostęp do tej funkcji",
    subscribe: "Subskrybuj",
    subscriptionRequired: "Wymagana subskrypcja",
    subscriptionRequiredMessage: "Subskrybuj, aby uzyskać dostęp do tej funkcji.",
    subscriptionOrSinglePaymentRequired: "Wymagana płatność",
    subscriptionOrSinglePaymentRequiredMessage: "Opłać dokument, aby go ukończyć",
    notifications: "Powiadomienia",
    youAreUpToDate: "Jesteś na bieżąco",
    markAllAsRead: "Oznacz wszystkie jako przeczytane",
    dateOfCreation: "Data utworzenia",
    type: "Rodzaj",
    amount: "Kwota",
    restore: "Przywróć",
    from: "Od",
    back: "Wstecz",
    forward: "Dalej",
    select: "Wybierz",
    sign: "Podpisz",
    menu: "Menu",
    buy: "Kup teraz",
    findDocument: "Znajdź",
    download: "Pobierz",
    sent_at: "Wysłano",
    opened_at: "Otwarto",
    history_of_sharing: "Historia udostępniania",
    settings: "Ustawienia",
    need_help: "Potrzebujesz pomocy?",
  },
  creator: {
    new_document: "Nowy dokument",
    wysiwyg_wizard_locked: "Najpierw odpowiedz na kilka pytań",
    wizard: "Kreator",
    form: "Formularz",
    copy_link: "Kopiuj link",
    share_email: "Udostępnij przez email",
    ask_for_email: "Wprowadź adres email, na który chcesz wysłać dokument",
    send: "Wyślij do weryfikacji",
    change_document_name: "Zmień nazwę dokumentu",
    change_document_name_description: "Wprowadź nową nazwę dla dokumentu",
    hidden_paragraph_tooltip: "Aby zobaczyć pełną treść, utwórz konto",
    toolbar: {
      font: "Czcionka",
      format: "Format",
      color: "Kolor",
      spacing: "Odstępy",
      size: "Rozmiar",
      text: "Tekst",
      background: "Tło",
      line_height: "Interlinia",
    },
  },
  notifications: {
    default_error: "Ups! Coś poszło nie tak.",
    default_update_success: "Zaktualizowano pomyślnie!",
    email_change: 'Zweryfikuj swój nowy adres email "{email}",  aby zakończyć zmianę.',
    login_success: "Zalogowano pomyślnie!",
    login_error: "Nieprawidłowe dane logowania",
    logout_success: "Wylogowano pomyślnie!",
    register_success: "Zarejestrowano pomyślnie!",
    auth_error_account_exists:
      "Konto z podanymi danymi już istnieje. Zaloguj się, aby kontynuować.",
    document_moved: "Przeniesiono dokument",
    document_saved: "Zapisano dokument",
    directory_removed: "Usunięto folder",
    directory_created: "Utworzono nowy folder",
    link_copied: "Skopiowano link",
    password_reset_success: "Zmieniono hasło",
    forgot_password_success: "Sprawdź skrzynkę email - wysłaliśmy link do zmiany hasła.",
    document_deleted: "Usunięto dokument",
    document_save_error: "Błąd podczas zapisywania dokumentu",
    chat_char_limit: "Wykorzystałęś limit czatu na ostatnie 30 dni. Sprawdź ponownie później!",
    logout_warning: "Zostaniesz wkrótce automatycznie wylogowany z powodu braku aktywności.",
    document_duplicated: "Utworzono kopię dokumentu",
    document_duplicate_error: "Ups! Nie udało się zduplikować dokumentu. Spróbuj ponownie.",
    document_name_updated: "Zmieniono nazwę dokumentu",
    wizard_completed:
      "Dokument gotowy! 🎉 Twój dokument został pomyślnie zapisany. Przejdź do sekcji 'Moje Dokumenty', aby nim zarządzać. Możesz go udostępnić do negocjacji, pobrać, wydrukować lub podpisać!",
  },
  errors: {
    global: {
      too_many_request:
        "Wykonałeś zbyt wiele zapytań. Poczekaj chwilę i spróbuj ponownie za minutę.",
    },
    documents: {
      not_found: "Nie znaleziono dokumentów",
      move_error: "Błąd podczas przenoszenia dokumentu",
      save_error: "Błąd podczas zapisywania dokumentu",
      directory_removal_error: "Błąd podczas usuwania folderu",
      directory_creation_error: "Błąd podczas tworzenia folderu",
      name_required: "Wymagana nazwa dokumentu",
      document_delete_error: "Błąd podczas usuwania dokumentu",
    },
    auth: {
      terms_required: "Wymagana akceptacja regulaminu i polityki prywatności.",
      invalid_credentials: "Nieprawidłowy email lub hasło",
    },
    search: {
      no_categories: "Nie udało się załadować listy kategorii",
    },
    editor: {
      prohibited_action: "Nie możesz usunąć tej treści",
      variable_value_required: "To pole jest wymagane",
    },
    chat: {
      limit_general: "Osiągnąłeś limit czatu na ostatnie 30 dni. Kup subskrypcję, aby kontynuować.",
      limit_document:
        "Osiągnąłeś limit czatu dla tego dokumentu. Kup subskrypcję, aby kontynuować.",
    },
    wizard: {
      max_options1: "Możesz wybrać maksymalnie",
      max_options2: "opcji",
      min_options: "Wybierz minimum jedną opcję",
    },
  },
  static_pages: {
    "Cookies Policy": "Polityka plików cookie",
    "Privacy Policy": "Polityka prywatności",
    "Terms Of Service": "Regulamin",
    "About Us": "O nas",
    "Frequently Asked Questions": "Często zadawane pytania",
    "AI Disclaimer": "Zastrzeżenia dotyczące AI",
    "Ai Disclaimer": "Zastrzeżenia dotyczące AI",
  },
  footer: {
    credits: "©2024 VAIOT Limited",
  },
  home: {
    active_documents: "Dokumenty w toku",
    latest_documents: "Ostatnio utworzone",
    latest_documents_mobile: "Ostatnio utworzone dokumenty",
  },
  account: {
    delete_account: "Usuń konto",
    delete_account_message: "Czy na pewno chcesz usunąć swoje konto?",
    delete_account_warning: "Uwaga! Ta akcja jest nieodwracalna.",
    delete_verify_code: 'Wpisz "USUŃ" aby potwierdzić usunięcie konta',
    delete_account_reason_message: "Dlaczego chcesz usunąć konto?",
    delete_account_reason: "Powód usunięcia",
    delete_account_reason_required: "Proszę podać powód usunięcia konta.",
    change_password: "Zmień hasło",
    current_password: "Aktualne hasło",
    new_password: "Nowe hasło",
    confirm_new_password: "Potwierdź nowe hasło",
    password_changed: "Hasło zostało zmienione pomyślnie",
  },
  email_popup: {
    title: "Dokończ rejestrację konta",
    description:
      "Jesteś już blisko! Dokończ rejestrację, aby z łatwością zarządzać wszystkimi dokumentami w jednym miejscu.",
    button: "Dokończ rejestrację",
  },
  term_popup: {
    title: "Wymagana akceptacja",
    description: {
      full: "Potwierdzam, że przeczytałem/am, zrozumiałem/am i akceptuję <a href='{termsLink}' target='_blank'>{terms}</a> and the <a href='{privacyLink}' target='_blank'>{privacy}</a> aplikacji, które opisują sposób przetwarzania moich danych osobowych. ",
    },
    button: "Akceptuję",
    terms_and_conditions: {
      title: "Warunki korzystania",
      link: "warunki korzystania",
    },
    privacy_policy: {
      title: "Polityka prywatności",
      link: "polityka prywatności",
    },
  },
  marketing_consent:
    "Zaznaczając to pole, wyrażasz zgodę na otrzymywanie wiadomości marketingowych.",
  profile: {
    user_profile: "Moje konto",
    account_details: "Szczegóły konta",
    change_password: "Zmiana hasła",
    contact_details: "Dane kontaktowe",
    invoice_details: "Dane do faktury",
  },
  wizard: {
    progress: "Postęp",
  },
  contact_form: {
    contact_support: "Wsparcie techniczne",
    name: "Imię i nazwisk",
    email: "Email",
    subject: "Temat",
    describe_your_problem: "Opisz swój problem",
    send: "Wyślij",
    message_sent_successfully: "Wiadomość wysłana pomyślnie",
    share_your_feedback: "Podziel się swoją opinią",
  },
  docusign: {
    auth_success: "Autoryzacja DocuSign zakończona pomyślnie",
    warning_blocking_windows:
      "Uwaga! Proces podpisywania dokumentu odbywa się przez DocuSign, który otworzy się w nowej karcie. Upewnij się, że Twoja przeglądarka nie blokuje tego okna.",
    upload_success: "Przesłano dokument do DocuSign",
    upload_error: "Błąd podczas przesyłania do DocuSign",
    connect_your_account: "Połącz swoje konto DocuSign",
    redirect_error: "Błąd przekierowania do DocuSign",
  },
  comments: {
    title: "Komentarze",
    add_comment: "Dodaj komentarz",
    comment_placeholder: "Wpisz swój komentarz tutaj...",
    comment_added: "Dodano komentarz",
    comment_removed: "Usunięto komentarz",
    comment_remove_error: "Błąd: Nie można usunąć komentarza",
    comment_add_error: "Błąd: Nie można dodać komentarza",
  },
  validation: {
    required: "To pole jest wymagane",
    email: "Nieprawidłowy adres email",
  },
};
